import { noop } from 'lodash';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose, withPropsOnChange } from 'recompose';

import { productShape } from 'shapes/product';
import makeProductForm from 'react/business/products/form/Form';

import { ProductFormCurrentStepContext } from 'react/business/products/form/Context';
import ProductFormSteps from 'react/business/products/form/steps/FormSteps';
import { PRODUCT_FORM_STEPS } from 'react/business/products/form/steps/form-steps.constants';
import withFormStepsTouched from 'react/business/products/form/steps/withFormStepsTouched';

import { elementShape } from 'shapes/vendor';

import ProductFormLayout from '../layout/Layout';
import ProductFormPreview from '../preview/Preview';
import withProductFromFormValues from '../withProductFromFormValues';
import withPreviewOpenState from '../preview/withPreviewOpenState';

import classNames from './product-create-form-page.module.scss';
import StepsRenderer from '../layout/StepsRenderer';

const ConnectedProductFormPreview = withProductFromFormValues(ProductFormPreview);

const DefaultTouchedProductFormSteps = compose(
  withFormStepsTouched(),
)(ProductFormSteps);

const enhancer = compose(
  withPropsOnChange(
    () => false,
    () => ({
      ProductForm: makeProductForm(),
    }),
  ),

  withPreviewOpenState,
  memo,
);

const ProductCreateFormPage = ({
  ProductForm,
  step,
  initialValues,
  onChangeStep,
  onEditProduct,
  onSubmitSuccess,
  onSubmitFail,
  isPreviewOpen,
  onOpenPreview,
  onClosePreview,
}) => (
  <ProductFormCurrentStepContext.Provider value={step}>
    <ProductForm
      initialValues={initialValues}
      onEditProduct={onEditProduct}
      onSubmitSuccess={onSubmitSuccess}
      onSubmitFail={onSubmitFail}
      className={classNames.form}
    >
      <ProductFormLayout
        steps={(
          <DefaultTouchedProductFormSteps
            value={step}
            onChange={onChangeStep}
          />
        )}
      >
        <StepsRenderer
          step={step}
          onChangeStep={onChangeStep}
          onOpenPreview={onOpenPreview}
        />

        <ConnectedProductFormPreview
          isOpen={isPreviewOpen}
          onRequestClose={onClosePreview}
        />
      </ProductFormLayout>
    </ProductForm>
  </ProductFormCurrentStepContext.Provider>
);

ProductCreateFormPage.propTypes = {
  ProductForm: elementShape.isRequired,
  step: PropTypes.string,
  onChangeStep: PropTypes.func,
  onEditProduct: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,
  /** Form initial values. */
  initialValues: productShape,
  isPreviewOpen: PropTypes.bool.isRequired,
  onOpenPreview: PropTypes.func.isRequired,
  onClosePreview: PropTypes.func.isRequired,
};

ProductCreateFormPage.defaultProps = {
  step: PRODUCT_FORM_STEPS.PRESENTATION,
  onChangeStep: noop,
  onEditProduct: noop,
  onSubmitSuccess: noop,
  onSubmitFail: noop,
  initialValues: null,
};

export default enhancer(ProductCreateFormPage);
